// es6 libraries
import "core-js/stable";
import "core-js/features/reflect";
import "regenerator-runtime/runtime";
import "symbol-observable";
import 'hammerjs/hammer';
import {
    JitCompilerFactory,
    platformBrowserDynamic
} from "@angular/platform-browser-dynamic";
import {BrowserModule} from "@angular/platform-browser";
import {
    Compiler,
    COMPILER_OPTIONS,
    CompilerFactory,
    enableProdMode,
    NgModule
} from "@angular/core";
import {
    RouteReuseStrategy,
    RouterModule
} from "@angular/router";
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// angular forms
import {FormsModule} from '@angular/forms';

// angular material
import {MatMenuModule} from "@angular/material/menu";
import {MatSortModule} from "@angular/material/sort";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTooltipModule} from "@angular/material/tooltip"

import PointivoAppFrameworkModule, {
    UserEditComponent,
    PreventUnsavedChangesGuard,
    OrganizationUsersComponent,
    NoAccessComponent,
    AuthGuard,
    RouteTitleService,
    RouteTitleTypes,
    UserInvitationReactionComponent,
    RedTheme,
    BlueTheme,
    LightBlueTheme,
    SymbolService,
    UserVerificationComponent,
    UserPasswordResetComponent,
    CustomLocationStrategy,
    CustomRouteReuseStrategy
} from "@wireframe-tool/pointivo-app-framework";
import {AppRootComponent} from "./components/appRoot/appRoot.component";
import {AboutComponent} from "./components/about/about.component";
import {PointivoAppThemesModule} from "./pointivoAppThemes.module";
import {APP_BASE_HREF, LocationStrategy} from '@angular/common';

// zone.js MUST be imported AFTER AppModule/AppModuleNgFactory, otherwise it will throw error "ZoneAware promise has been overridden" during bootstrapping
import 'zone.js/dist/zone';

// application mode
let applicationMode = null;
if (process && process.env) {
    applicationMode = process.env.NODE_ENV;
}
if (!applicationMode) applicationMode = 'development';
console.log('application mode', applicationMode);
if (applicationMode === 'production') {
    enableProdMode();
}

function createCompiler(compilerFactory: CompilerFactory) {
    return compilerFactory.createCompiler();
}

const matIconRegistryFactory = (symbolService: SymbolService) => {
    return symbolService.matIconRegistry;
};

// angular 6
@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,
        MatSnackBarModule,
        PointivoAppFrameworkModule.forRoot(),
        PointivoAppThemesModule.forRoot({
            themes: [BlueTheme, RedTheme, LightBlueTheme],
            active: 'blue'
        }),
        RouterModule.forRoot([
                {
                    path: 'about',
                    component: AboutComponent
                },
                {
                    path: 'user/edit',
                    component: UserEditComponent,
                    canActivate: [AuthGuard],
                    canDeactivate: [PreventUnsavedChangesGuard],
                    data: {
                        usingRootHeader: true
                    }
                },
                {
                    path: 'organization/users',
                    component: OrganizationUsersComponent,
                    canActivate: [AuthGuard],
                    data: {
                        title: 'Members',
                        type: RouteTitleTypes.Administration,
                        usingRootHeader: true
                    }
                },
                {
                    path: "invite/:invitation_code",
                    component: UserInvitationReactionComponent,
                    data: {
                        title: 'User Invitation',
                        usingRootHeader: true
                    }
                },
                {
                    path: 'not-found',
                    component: NoAccessComponent,
                    data: {
                        isNotFoundPage: true
                    }
                },
                {
                    path: 'verify',
                    component: UserVerificationComponent
                },
                {
                    path: 'reset-password',
                    component: UserPasswordResetComponent
                }
            ],
            {
                useHash: true,
                initialNavigation: 'disabled',
                enableTracing: false,
                onSameUrlNavigation: "reload"
            })
    ],
    declarations: [
        AppRootComponent,
        AboutComponent
    ],
    entryComponents: [
        AppRootComponent,
        AboutComponent
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: "/"
        },
        {
            provide: LocationStrategy,
            useClass: CustomLocationStrategy
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        {
            provide: COMPILER_OPTIONS,
            useValue: {},
            multi: true
        },
        {
            provide: CompilerFactory,
            useClass: JitCompilerFactory,
            deps: [COMPILER_OPTIONS]
        },
        {
            provide: Compiler,
            useFactory: createCompiler,
            deps: [CompilerFactory]
        },
        {
            provide: MatIconRegistry,
            useFactory: matIconRegistryFactory,
            deps: [SymbolService]
        },
        RouteTitleService
    ],
    bootstrap: [
        AppRootComponent
    ]
})
export class PointivoAppHostModule {
    public constructor(private routeTitleService: RouteTitleService) {
        this.routeTitleService.init('Viewer');
    }

    ngDoBootstrap() {
    }
}

platformBrowserDynamic().bootstrapModule(PointivoAppHostModule);