import { Component, OnInit } from '@angular/core';
import {
    ModuleService,
    SuiteService,
    Suite,
    SystemService,
    SystemInformationModel,
    SystemBuildInformationModel
} from "@wireframe-tool/pointivo-app-framework";

@Component({
    selector: 'ptv-about',
    template: require('./about.component.html'),
    styles: [require('./about.component.scss')]
})
export class AboutComponent implements OnInit {

    // suite
    suite: Suite;

    // modules
    modules: any;

    // system information
    systemInformationModel: SystemInformationModel;

    // build information
    systemBuildInformationModel: SystemBuildInformationModel;

    constructor(
        private moduleService: ModuleService,
        private suiteService: SuiteService,
        private systemService: SystemService
    ) {
    }

    ngOnInit() {
        // get the current suite
        this.suiteService.getSuite().then((suite: Suite) => {
            this.suite = suite;
        })

        // get all modules
        this.moduleService.getModules().then((modules)=>{
            this.modules = modules;
        })

        // get system information
        this.systemService.getSystemInformation().then((systemInformationModel) => {
            this.systemInformationModel = systemInformationModel;
        })

        // todo: get system build information
        // this.systemService.getSystemBuildInformation().then((systemBuildInformationModel) => {
        //     console.log('systemBuildInformationModel', systemBuildInformationModel);
        //     this.systemBuildInformationModel = systemBuildInformationModel;
        // })
    }
}