// angular themes
import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
    ThemeOptions,
    THEMES,
    ACTIVE_THEME
} from "@wireframe-tool/pointivo-app-framework";

@NgModule({
    imports: [CommonModule]
})
export class PointivoAppThemesModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders<PointivoAppThemesModule> {
        return {
            ngModule: PointivoAppThemesModule,
            providers: [
                {
                    provide: THEMES,
                    useValue: options.themes
                },
                {
                    provide: ACTIVE_THEME,
                    useValue: options.active
                }
            ]
        };
    }
}