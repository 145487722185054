import {
    ChangeDetectorRef,
    Component,
    ViewEncapsulation
} from '@angular/core';
import {
    AppHostService,
    AppHostComponent,
    LogoTypes,
    TosService,
    SymbolDefinition,
    Suite,
    UserModel,
    UserInformationConfiguration
} from "@wireframe-tool/pointivo-app-framework";
import {AppHostScopes} from "../../models/scopes/appHostScopes";
import Symbols from '../../symbols.json';
import PublicUrls from '../../publicUrls.json';

@Component({
    selector: 'ptv-app-root',
    template: require('./appRoot.component.html'),
    styles: [require('./appRoot.component.scss')],
    encapsulation: ViewEncapsulation.None
})
export class AppRootComponent extends AppHostComponent {

    // environment
    environment: any;

    // suite
    suite: Suite;

    // logos
    logoTypes = LogoTypes;

    // powered by
    showPoweredByLogo: boolean = false;

    // company
    showCompany: boolean = false;
    companyName: string = '';
    companyLink: string = '';

    // documentation
    showDocumentation: boolean = false;
    documentationName: string = '';
    documentationLink: string = '';

    // configuration
    userInformationConfiguration: UserInformationConfiguration;

    constructor(
        protected appHostService: AppHostService,
        protected changeDetectorRef: ChangeDetectorRef,
        private tosService: TosService
    ) {
        super(appHostService, changeDetectorRef, PublicUrls);

        // extend icons
        let symbolNames = Symbols ? Symbols : [];
        let symbolDefinitions: SymbolDefinition[] = [];
        symbolNames.forEach(symbolName => {
            symbolDefinitions.push(new SymbolDefinition(symbolName, require(`svg/symbols/${symbolName}.svg`)))
        });
        this.symbolService.addSymbols("appRoot", symbolDefinitions);

        // tos check
        this.addSubscription(this.tosService.enableTosCheck());
    }

    ngOnInit(): Promise<void> {
        return super.ngOnInit().then(() => {
            // get the environment
            this.environmentService.loadEnvironment().then((environment) => {
                this.environment = environment;

                // configure company
                let companyName = environment?.companyName;
                let companyLink = environment?.companyLink;
                let useDefaultCompany = true;
                if (useDefaultCompany) {
                    if (!companyName) {
                        companyName = "Pointivo"
                    }
                    if (!companyLink) {
                        companyLink = "http://www.pointivo.com"
                    }
                }
                this.companyLink = companyLink;
                this.companyName = companyName;
                this.showCompany = !!companyLink;

                // configure documentation
                let documentationName = environment?.documentationName;
                let documentationLink = environment?.documentationLink;
                let useDefaultDocumentation = false;
                if (useDefaultDocumentation) {
                    if (!documentationName) {
                        documentationName = "Help Center"
                    }
                    if (!documentationLink) {
                        documentationLink = "http://www.pointivo.com/help"
                    }
                }
                this.documentationLink = documentationLink;
                this.documentationName = documentationName;
                this.showDocumentation = !!documentationLink;
            })

            // get the suite
            this.suiteService.getSuite().then((suite: Suite) => {
                this.suite = suite;
            })

            // active organization
            this.addSubscription(this.organizationService.activeOrganizationObservable.subscribe((organizationModel) => {
                this.updateOrganization(organizationModel);
            }));

        });
    }

    onUserReady(userModel: UserModel): Promise<void> {
        return super.onUserReady(userModel).then(() => {
            // configure
            let userInformationConfiguration = new UserInformationConfiguration();
            userInformationConfiguration.disableUserEdit = !this.userHasScope(AppHostScopes.Accounts);
            userInformationConfiguration.disableOrganizationEdit = !this.userHasScope(AppHostScopes.Accounts);
            this.userInformationConfiguration = userInformationConfiguration;
        })
    }

    onSuiteClick() {
        // navigate to the about route
        this.routerService.navigate('about')
    }

    updateOrganization(organizationModel) {
        if (!organizationModel) return;
        this.showPoweredByLogo = !(organizationModel.is_root);
    }
}